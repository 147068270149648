import { fork, all } from "redux-saga/effects";
import {
  userWatcherLoginRequest,
  userWatcherLogout,
  userWatcherGetUserById,
  userWatcherGetUserProfile,
  userWatcherGetUserEdificios,
  userWatcherCheckAuthStatus
} from "./users/sagas";
import {
  edificiosWatcherGetEdificios,
  edificiosWatcherPostEdificio,
  edificiosWatcherGetEdificioById,
  edificiosWatcherGetEdificiosUnread,
  edificiosWatcherGetEdificioCopropietario,
  edificiosWatcherGetEdificioInformes
} from "./edificios/sagas";
import { obrasWatcherGetObrasByEdificio, obrasWatcherGetObraById } from "./obras/sagas"
import { informesWatcherGetInformesByTecnico, informesWatcherGetInformesByEdificio, informesWatcherGetInformeById } from "./informes/sagas";
import { visitasWatcherGetVisitasByObra } from "./visitas/sagas";

function* rootSaga() {
  yield all([
    fork(userWatcherLoginRequest),
    fork(userWatcherCheckAuthStatus),
    fork(userWatcherLogout),
    fork(userWatcherGetUserProfile),
    fork(userWatcherGetUserEdificios),
    fork(userWatcherGetUserById),
    fork(edificiosWatcherGetEdificios),
    fork(edificiosWatcherPostEdificio),
    fork(edificiosWatcherGetEdificioById),
    fork(edificiosWatcherGetEdificiosUnread),
    fork(edificiosWatcherGetEdificioInformes),
    fork(edificiosWatcherGetEdificioCopropietario),
    fork(obrasWatcherGetObrasByEdificio),
    fork(obrasWatcherGetObraById),
    fork(visitasWatcherGetVisitasByObra),
    fork(informesWatcherGetInformesByTecnico),
    fork(informesWatcherGetInformesByEdificio),
    fork(informesWatcherGetInformeById),
  ]);
}

export { rootSaga };
