import { Reducer } from "redux";
import { VisitaTypes, VisitaState, VisitaActionTypes } from "./types";

const initialState: VisitaState = {
    visitasLoad: false,
    visitasByObra: [],
    message: "",
    errorMessage: ""
}

export const visitaReducer: Reducer<VisitaState> = (
    state = initialState,
    action: VisitaActionTypes
) => {
    switch (action.type) {
        case VisitaTypes.GET_VISITAS_BY_OBRA: return {
            ...state,
            inPromise: true,
            visitasLoad: false,
            message: "Cargando..."
        };
        case VisitaTypes.SET_VISITAS_BY_OBRA:
            return {
                ...state,
                visitasLoad: true,
                inPromise: false,
                visitasByObra: action.payload.visitas,
                message: "Visitas cargadas exitosamente",
            };
        case VisitaTypes.SET_VISITAS_BY_OBRA_ERROR:
            return {
                ...state,
                inPromise: false,
                errorMessage: action.payload.mensaje
            };
        case VisitaTypes.LOGOUT:
            return { ...initialState };
        default:
            return state;
    }
}