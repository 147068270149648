import {
    IonBackButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform,
    useIonViewDidEnter,
    useIonViewWillEnter,
} from "@ionic/react";
import "../../styles/App.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/ducks/rootReducer";
import React, { useEffect } from "react";
import { constructOutline, chatbubbleEllipsesOutline,  businessOutline,  documentTextOutline,  walletOutline, megaphoneOutline } from "ionicons/icons";
import { getEdificioCopropietario } from "../../store/ducks/edificios/actions";
import ToolbarMenu from "../../components/Toolbars/ToolbarMenu";
import EscritorioItem from "../../components/Copropietario/Escritorio/EscritorioItem";

const EscritorioCopropietario: React.FC = () => {
    const { edificioCopropietario } = useSelector((state: RootState) => state.edificios);
    const informeReciente: any = edificioCopropietario?.informes ? edificioCopropietario.informes[0] : undefined;

    const dispatch = useDispatch();
    useEffect(() => {
        if(!edificioCopropietario?._id)dispatch(getEdificioCopropietario());
    }, []);


    return (
        <IonPage>
            <IonHeader className="ion-no-border border-header">
                <ToolbarMenu title="Edificio" />
            </IonHeader>

            <IonContent fullscreen className="ion-content-grey">
                <IonGrid>
                    <IonRow className="dashboard-row">
                        <IonCol size-md="6" size-lg="4" size-xl="3" size-xs="12" size-sm="6">
                            <EscritorioItem
                                title="Informes técnicos"
                                cantidad={edificioCopropietario?.informes?.length.toString()}
                                link='/copropietario/informes'
                                iconName={documentTextOutline}
                                fechaPublicacion={informeReciente?.updatedAt}
                                displayCantidad={true}
                            />
                        </IonCol>
                        <IonCol size-md="6" size-lg="4" size-xl="3" size-xs="12" size-sm="6">
                            <EscritorioItem
                                title="Memorias Constructivas"
                                cantidad={edificioCopropietario?.memorias?.length.toString()}
                                link='/memorias'
                                iconName={constructOutline}
                                displayCantidad={true}

                            />
                        </IonCol>
                        <IonCol size-md="6" size-lg="4" size-xl="3" size-xs="12" size-sm="6">
                            <EscritorioItem
                                title="Presupuestos"
                                cantidad={edificioCopropietario?.presupuestos?.length.toString()}
                                link='/copropietario/presupuestos'
                                iconName={walletOutline}
                                displayCantidad={true}

                            />
                        </IonCol>
                        <IonCol size-md="6" size-lg="4" size-xl="3" size-xs="12" size-sm="6">
                            <EscritorioItem
                                title="Obras"
                                cantidad={edificioCopropietario?.obras?.length.toString()}
                                link={`/copropietario/obras`}
                                iconName={businessOutline}
                                displayCantidad={true}

                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <IonGrid className="pb-50">
                    <IonRow className="dashboard-row">
                        <IonCol size-md="6" size-lg="4" size-xl="3" size-xs="12" size-sm="6">
                            <EscritorioItem
                                title="Reportar desperfectos"
                                link='/avisos'
                                iconName={megaphoneOutline}
                                displayCantidad={false}
                                descripcion="Informa sobre cualquier desperfecto edilicio para que el equipo técnico, la administración y los copropietarios puedan estar al tanto y coordinar la resolución"
                            />
                        </IonCol>
                        <IonCol size-md="6" size-lg="4" size-xl="3" size-xs="12" size-sm="6">
                            <EscritorioItem
                                title="Consultas"
                                link='/consultas'
                                iconName={chatbubbleEllipsesOutline}
                                displayCantidad={false}
                                descripcion="Envía consultas al equipo técnico para resolver problemas edilicios y obtener asesoramiento especializado sobre posibles soluciones"
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
                
            </IonContent>
        </IonPage>
    );
};

export default EscritorioCopropietario;
