import { action } from "typesafe-actions";
import { VisitaTypes, IVisita } from "./types"

export const getVisitasByObra = (id: string) =>
    action(VisitaTypes.GET_VISITAS_BY_OBRA, { id });
export const setVisitasByObra = (visitas: IVisita[]) =>
    action(VisitaTypes.SET_VISITAS_BY_OBRA, { visitas });
export const setVisitasByObraError = (mensaje: string) =>
    action(VisitaTypes.SET_VISITAS_BY_OBRA_ERROR, { mensaje });

const actions = {
    getVisitasByObra: getVisitasByObra,
    setVisitasByObra: setVisitasByObra,
    setVisitasByObraError: setVisitasByObraError
};

export { actions as visitaActions };    