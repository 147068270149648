import { IEdificio } from "../edificios/types"
import { IObra } from "../obras/types";

/**
 * types
 */
export enum VisitaTypes {
    /*GET VISITAS*/
    GET_VISITAS_BY_OBRA = "GET_VISITAS_BY_OBRA",
    SET_VISITAS_BY_OBRA = "SET_VISITAS_BY_OBRA",
    SET_VISITAS_BY_OBRA_ERROR = "SET_VISITAS_BY_OBRA_ERROR",
    /*LOGOUT*/
    LOGOUT = "LOGOUT"
}

/**
 * Data types
 */

export interface IVisita {
    _id?: any;
    obra?: IObra;
    titulo?: string;
    resumen?: string;
    responsablePresente?: string;
    avance?: number;
    fechaVisita?: Date;
    observaciones?: any[];
    relevamientoFotografico?: any[];
    notas?: any[];
    imgUrl?: string;
    empresa?: any;
    presupuesto?: any;
    recaudosGraficos?: any[];
    visitas?: any[];
    createdBy?: any;
    updatedBy?: any;
    activo?: boolean;
    createdAt?: Date;
    updatedAt?: Date;
    informeVisitaUrl?: string;
}

/**
 * State type
 */
export interface VisitaState {
    visitasLoad: boolean;
    visitasByObra: IVisita[] | [];
    message: string;
    errorMessage: string;
}

/**
 * Action type
 */
export interface GetVisitasByObraAction {
    type: typeof VisitaTypes.GET_VISITAS_BY_OBRA;
    payload: { id: string }
}
export interface SetVisitasByObraAction {
    type: typeof VisitaTypes.SET_VISITAS_BY_OBRA;
    payload: { visitas: IVisita[] }
}
export interface LogoutAction {
    type: typeof VisitaTypes.LOGOUT;
}
export interface SetVisitasByObraErrorAction {
    type: typeof VisitaTypes.SET_VISITAS_BY_OBRA_ERROR;
    payload: { mensaje: string };
}

export type VisitaActionTypes =
    | GetVisitasByObraAction
    | SetVisitasByObraAction
    | LogoutAction
    | SetVisitasByObraErrorAction