import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
} from "@ionic/react";
import "./EscritorioItem.css";
import "../../General.css";
import { businessOutline } from 'ionicons/icons';
import React, { ReactElement } from "react";
import moment from "moment";
import "moment/locale/es";
import { FiCalendar } from "react-icons/fi";

export type CardProps = {
  title: string;
  fechaPublicacion?: Date;
  iconClassName?: string;
  link: string;
  iconName: string;
  cantidad?: string;
  displayCantidad: boolean;
  descripcion?: string | undefined
};

const EscritorioItem: React.FC<CardProps> = ({
  title,
  link,
  iconName,
  cantidad,
  fechaPublicacion,
  displayCantidad,
  descripcion
}) => {
  return (
    <IonCard className="ion-card-menu escritorio-item-card-content" routerLink={link} routerDirection="root">
      <IonCardHeader>
        <IonIcon icon={iconName} className="ion-icon-menu-detalles"></IonIcon>
      </IonCardHeader>
      <IonCardContent>
        <h4 className="font-18 mb-3 text-capitalize font-bold font-dark-grey ">
          {title}
        </h4>
        {descripcion !== undefined && <p className="mb-3 font-14">
          {descripcion}
        </p>}
        {displayCantidad &&
          (<div className="d-flex align-items-center justify-content-between mb-3 h-100">
            <h4 className="font-16">
              Registros
            </h4>
            {cantidad !== undefined ?
              <h2 className="font-22 font-bold font-dark-grey">{cantidad}</h2> :
              <h2 className="font-22 font-bold font-dark-grey">0</h2>}

          </div>)
        }

        <div className="list-info-date d-flex ion-align-items-center">
          {fechaPublicacion !== undefined ?
            <span className="font-13">
              {`Actualizado el ${moment(fechaPublicacion).locale("es").format("LL")}`}
            </span> : <span className="font-13">
              {`Sin actualizaciones`}
            </span>}

        </div>
      </IonCardContent>
    </IonCard>
  );
}

export default EscritorioItem;
