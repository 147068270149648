import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../store/ducks/rootReducer";
import { useEffect, useState } from "react";
import { getUserId } from "../utils/auth";
import CenteredLoader from "../components/CenteredLoader/CenteredLoader";

const LoginRoute = ({ component: Component, userHomePath, ...rest }: any) => {
  const { isLogged, checkingAuth } = useSelector((state: RootState) => state.users);
  console.log("isLogged:", isLogged);
  console.log("checkingAuth:", checkingAuth);

  return (
    <Route
      {...rest}
      render={(props) =>
        !isLogged && checkingAuth ? (
          <CenteredLoader isOpen={checkingAuth} message="Verificando autenticación..." />
        ) : !isLogged && !checkingAuth ? (
          <Component {...props} />
        ) : (
          <Redirect to={userHomePath} />
        )
      }
    />
  );
};

export default LoginRoute;
