import {
    IonTabs,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonLabel
} from "@ionic/react";
import { Redirect } from "react-router";
import {
    businessOutline,
    gridOutline,
    documentOutline,
    walletOutline,
    megaphone,
    megaphoneOutline
} from "ionicons/icons";
import "./TabsCopropietario.css";
import EscritorioCopropietario from "../EscritorioCopropietario/EscritorioCopropietario";
import InformeCopList from "../InformesCopropietario/InformeCopList";
import InformeCopDetail from "../InformesCopropietario/InformeCopDetail";
import PresupuestosCopList from "../PresupuestosCopropietario/PresupuestosCopList";
import ObrasCopList from "../ObrasCopropietario/ObrasCopList";
import ObraCopDetail from "../ObrasCopropietario/ObraCopDetail";
import InformeCopArchivos from "../InformesCopropietario/InformeCopArchivos";
import InformeCopPresupuestos from "../InformesCopropietario/InformeCopPresupuestos";
import InformeCopNotas from "../InformesCopropietario/InformeCopNotas";
import InformeCopObras from "../InformesCopropietario/InformeCopObras";
import InformeCopMemorias from "../InformesCopropietario/InformeCopMemorias";
import PrivateRoute from "../../routes/PrivateRoute";

import ReportesCopList from "../ReportesCopropietarios/ReportesCopList";
import ObraCopSeguimiento from "../ObrasCopropietario/ObraCopSeguimiento";


type TabsCopropietarioProps = {

};

const TabsCopropietario: React.FC<TabsCopropietarioProps> = ({

}) => {
    
    return (

        <IonTabs>
            <IonRouterOutlet>
                <PrivateRoute
                    exact path="/copropietario/escritorio"
                    component={EscritorioCopropietario}
                />
                <PrivateRoute
                    exact path="/copropietario/informes"
                    component={InformeCopList}
                />
                <PrivateRoute
                    exact path="/copropietario/informes/:informeId"
                    component={InformeCopDetail}
                />
                <PrivateRoute
                    path="/copropietario/informes/archivos/:informeId"
                    component={InformeCopArchivos}
                />

                <PrivateRoute
                    path="/copropietario/informes/presupuestos/:informeId"
                    component={InformeCopPresupuestos}
                />
                <PrivateRoute
                    path="/copropietario/informes/obras/:informeId"
                    component={InformeCopObras}
                />
                <PrivateRoute
                    path="/copropietario/informes/notas/:informeId"
                    component={InformeCopNotas}
                />
                <PrivateRoute
                    path="/copropietario/informes/memorias/:informeId"
                    component={InformeCopMemorias}
                />
                <PrivateRoute
                    exact path="/copropietario/presupuestos"
                    component={PresupuestosCopList}
                />
                <PrivateRoute
                    exact path="/copropietario/reportes"
                    component={ReportesCopList}
                />
                <PrivateRoute
                    exact path="/copropietario/obras"
                    component={ObrasCopList}
                />
                <PrivateRoute
                    exact path="/copropietario/obras/:obraId"
                    component={ObraCopDetail}
                />
                <PrivateRoute
                    exact path="/copropietario/obras/seguimiento/:obraId"
                    component={ObraCopSeguimiento}
                />
                <PrivateRoute exact path="/copropietario" component={() => <Redirect to="/copropietario/escritorio" />} />

            </IonRouterOutlet>

            <IonTabBar slot="bottom" className="bottom-tab-det">
                <IonTabButton
                    tab="escritorioCop"
                    href={`/copropietario/escritorio`}
                    className="tab-button-det"
                >
                    <IonIcon icon={gridOutline} />
                    <IonLabel>Inicio</IonLabel>

                </IonTabButton>


                <IonTabButton
                    tab="presupuestosCop"
                    href={`/copropietario/reportes`}
                    className="tab-button-det"
                >
                    <IonIcon icon={megaphoneOutline} />
                    <IonLabel>Reportes</IonLabel>
                    {/* <IonBadge className="badge-background-det">6</IonBadge> */}
                </IonTabButton>
                <IonTabButton
                    tab="informesCop"
                    href={`/copropietario/informes`}
                    className="tab-button-det"
                >
                    <IonIcon icon={documentOutline} />
                    <IonLabel>Informes</IonLabel>
                    {/* <IonBadge className="badge-background-det">1</IonBadge> */}
                </IonTabButton>
                <IonTabButton
                    tab="obrasCop"
                    href={`/copropietario/obras`}
                    className="tab-button-det"
                >
                    <IonIcon icon={businessOutline} />
                    <IonLabel>Obras</IonLabel>
                    {/* <IonBadge className="badge-background-det">6</IonBadge> */}
                </IonTabButton>

            </IonTabBar>
        </IonTabs>
    );
}
export default TabsCopropietario;


